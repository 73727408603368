import { Box, Button, Typography } from '@mui/material';
import { dropSx, uploadSectionSx, uploadSx } from './UploadSection.style';
import { Field } from 'formik';
import { UploadSectionPropsType } from './UploadSection.type';
import { FileIcon } from '../../../../icons/FileIcon';
import { UploadIcon } from '../../../../icons/UploadIcon';

export const UploadSection = ({
  setFieldValue,
  isSubmitted,
}: UploadSectionPropsType) => {
  const openFilePicker = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput && fileInput.click();
  };

  return (
    <Box sx={uploadSectionSx}>
      <Box>
        <UploadIcon />
      </Box>
      <Typography sx={dropSx}>Drop your specification file here</Typography>
      <Typography sx={{ padding: '16px 0' }} variant="body2" color="#575757">
        OR
      </Typography>
      <Button onClick={openFilePicker} sx={uploadSx} startIcon={<FileIcon />}>
        Choose file
        <Field name="specification">
          {() => (
            <input
              type="file"
              accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              id="fileInput"
              hidden
              onChange={(event) => {
                const file =
                  event.currentTarget.files && event.currentTarget.files[0];
                setFieldValue('specification', file);
              }}
            />
          )}
        </Field>
      </Button>
      <Typography
        sx={{ padding: '12px 0 109px' }}
        variant="body2"
        color="#575757"
      >
        DOCX, PDF
      </Typography>
      {isSubmitted && <div>Form has been submitted!</div>}
    </Box>
  );
};
