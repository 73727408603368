import { containerSx, formSx, inputsBlock, submitBtnSx } from './AIForm.style';
import { Formik, Form } from 'formik';
import { Box, Button, Paper } from '@mui/material';
import { useState } from 'react';
import { UploadSection } from './components/UploadSection/UploadSection.component';
import { InputField } from './components/InputField/InputField.component';
import { DOMAINS } from './components/InputField/components/SelectField/constants';
import { AddSpecificationManuallySection } from './components/AddSpecificationManuallySection/AddSpecificationManuallySection.component';
import { FormValues } from './AiForm.type';

export const AiForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const initialValues = {
    businessDomain: '',
    companyName: '',
    email: '',
    specification: null,
  };

  const handleSubmit = (
    values: FormValues,
    { resetForm }: { resetForm: () => void },
  ) => {
    console.log(values);
    resetForm();
    setIsSubmitted(true);
    setTimeout(() => {
      setIsSubmitted(false);
    }, 1500);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue }) => (
        <Form>
          <Paper sx={formSx}>
            <Box sx={containerSx}>
              <UploadSection
                setFieldValue={setFieldValue}
                isSubmitted={isSubmitted}
              />
              <AddSpecificationManuallySection />
            </Box>
            <Box sx={inputsBlock}>
              <InputField
                label="Business domain"
                name="businessDomain"
                type="select"
                options={DOMAINS}
              />
              <InputField
                label="Company name"
                name="companyName"
                placeholder="Company name"
              />
              <InputField
                label="Your email"
                name="email"
                type="email"
                placeholder="email@example.com"
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* align button with inputs */}
                <Box sx={{ height: 'fit-content' }}></Box>
                <Button type="submit" sx={submitBtnSx}>
                  Receive estimate
                </Button>
              </Box>
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
