import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { AiForm } from '../AiForm/AiForm.component';
import { questionnaireBtnSx, aiBtnSx, btnsContainer } from './Calculator.style';
import { AiIcon } from '../../icons/AiIcon';
import { QuestionnaireIcon } from '../../icons/Questionnaire';

export const Calculator = () => {
  const [isAi, setIsAi] = useState<boolean>(true);

  const goToQuestionnaire = () => {
    setIsAi(false);
    window.location.href =
      'https://devlitslink.wpengine.com/app-cost-calculator';
  };

  const goToAi = () => {
    setIsAi(true);
    window.location.href = 'https://devlitslink.wpengine.com/calculator-test';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box display="flex" sx={btnsContainer}>
        <Button
          onClick={() => goToQuestionnaire()}
          variant="outlined"
          sx={questionnaireBtnSx}
          startIcon={<QuestionnaireIcon />}
        >
          Questionnaire
        </Button>
        <Button
          onClick={() => goToAi()}
          variant="contained"
          sx={aiBtnSx}
          startIcon={<AiIcon />}
        >
          AI
        </Button>
      </Box>
      {isAi ? <AiForm /> : null}
    </Box>
  );
};
