import { MenuItem, Select } from '@mui/material';
import { Field } from 'formik';
import { selectSx } from './SelectField.style';
import { SelectFieldPropsType } from './SelectField.type';

export const SelectField = ({ name, options }: SelectFieldPropsType) => (
  <Field
    as={Select}
    name={name}
    variant="outlined"
    fullWidth
    sx={selectSx}
    displayEmpty
  >
    {options?.map((option) => (
      <MenuItem
        key={option.value}
        value={option.value}
        disabled={option.value === ''}
      >
        <em>{option.label}</em>
      </MenuItem>
    ))}
  </Field>
);
