import { ThemeProvider, Box } from '@mui/material';
import { Calculator } from './components/Calculator/Calculator.component';
import { theme } from './theme/theme';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Box sx={{ textAlign: 'center' }}>
          <Calculator />
        </Box>
      </ThemeProvider>{' '}
    </BrowserRouter>
  );
}

export default App;
