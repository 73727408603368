import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
  },
});
