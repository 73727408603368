import { InputWrapper } from './components/InputWrapper/InputWrapper.component';
import { InputLabel } from '@mui/material';
import { SelectField } from './components/SelectField/SelectField.component';
import { formLabelSx } from './InputField.style';
import { TextFieldComponent } from './components/TextFieldComponent/TextField.component';
import { InputFieldPropsType } from './InputField.type';

export const InputField = ({
  label,
  name,
  type = 'text',
  placeholder,
  options,
}: InputFieldPropsType) => (
  <InputWrapper>
    <InputLabel sx={formLabelSx}>{label}</InputLabel>
    {type === 'select' ? (
      <SelectField name={name} options={options} />
    ) : (
      <TextFieldComponent name={name} type={type} placeholder={placeholder} />
    )}
  </InputWrapper>
);
