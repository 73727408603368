export const btnSx = {
  borderRadius: '8px',
  border: '1px solid #D1D1D1',
  textTransform: 'none',
  color: '#575757',
};

export const btnWrapperSx = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '98%',
  padding: '16px',
};
