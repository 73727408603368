import { SvgIcon } from '@mui/material';

export const UploadIcon = () => {
  return (
    <SvgIcon sx={{ width: '72px', height: '72px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="72"
        viewBox="0 0 72 73"
        fill="none"
      >
        <path
          d="M41.76 6.77539C34.2506 6.77539 27.8944 11.2698 24.84 17.6204C23.2144 16.501 21.33 15.6854 19.215 15.6854C13.8488 15.6854 9.56812 19.9323 9.27 25.2254C3.90375 27.1604 0 32.1666 0 38.1854C0 45.8579 6.2325 52.0904 13.905 52.0904H25.92C26.4375 52.096 26.9213 51.826 27.1856 51.376C27.4444 50.926 27.4444 50.3748 27.1856 49.9248C26.9213 49.4748 26.4375 49.2048 25.92 49.2104H13.905C7.785 49.2104 2.88 44.2998 2.88 38.1854C2.88 33.0948 6.31125 28.8085 10.98 27.5204C11.6156 27.3348 12.0431 26.7385 12.015 26.0804C12.0094 25.8779 12.015 25.7766 12.015 25.7654C12.015 21.766 15.2156 18.5654 19.215 18.5654C21.2175 18.5654 23.04 19.3698 24.345 20.6804C24.6881 21.0123 25.1775 21.1585 25.6444 21.0573C26.1169 20.956 26.505 20.6298 26.685 20.1854C28.9519 14.0654 34.8356 9.65539 41.76 9.65539C50.6475 9.65539 57.825 16.8385 57.825 25.7204C57.825 26.3785 57.7744 27.0254 57.69 27.7004C57.6394 28.1054 57.7631 28.5104 58.0275 28.8198C58.2919 29.1291 58.68 29.3091 59.085 29.3204H59.175C64.6819 29.3204 69.12 33.7585 69.12 39.2654C69.12 44.7723 64.6819 49.2104 59.175 49.2104H46.08C45.5625 49.2048 45.0788 49.4748 44.8144 49.9248C44.5556 50.3748 44.5556 50.926 44.8144 51.376C45.0788 51.826 45.5625 52.096 46.08 52.0904H59.175C66.24 52.0904 72 46.3304 72 39.2654C72 32.7123 66.9713 27.5035 60.615 26.7554C60.6375 26.4066 60.705 26.0804 60.705 25.7204C60.705 15.2804 52.2 6.77539 41.76 6.77539ZM36 31.3454L34.965 32.3354L27.765 39.5354C27.1913 40.1091 27.1913 41.0316 27.765 41.6054C28.3388 42.1791 29.2613 42.1791 29.835 41.6054L34.56 36.8804V62.1704C34.5544 62.6879 34.8244 63.1716 35.2744 63.436C35.7244 63.6948 36.2756 63.6948 36.7256 63.436C37.1756 63.1716 37.4456 62.6879 37.44 62.1704V36.8804L42.165 41.6054C42.7388 42.1791 43.6613 42.1791 44.235 41.6054C44.8088 41.0316 44.8088 40.1091 44.235 39.5354L37.035 32.3354L36 31.3454Z"
          fill="url(#paint0_linear_4120_3225)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4120_3225"
            x1="36"
            y1="-21.652"
            x2="-19.3052"
            y2="48.3858"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#32C5FF" />
            <stop offset="0.512584" stopColor="#B620E0" />
            <stop offset="1" stopColor="#F7B500" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
