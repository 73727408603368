export const selectSx = {
  borderRadius: '12px',
  '& .MuiSelect-select.MuiSelect-outlined': {
    color: '#848484',
    textAlign: 'left',
  },
  '&.Mui-focused .MuiSelect-select.MuiSelect-outlined': {
    color: '#848484',
  },
};
