export const uploadSectionSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '168px',
  gap: '3',
};

export const dropSx = { paddingTop: '16px', fontSize: '20px', fontWeight: 600 };

export const uploadSx = {
  borderRadius: '12px',
  border: '1px solid #D1D1D1',
  display: 'flex',
  height: '44px',
  width: '154px',
  padding: '16px',
  fontWeight: 600,
  fontSize: '16px',
  textTransform: 'none',
  color: '#15172E',
};
