import { Field } from 'formik';
import { TextField } from '@mui/material';
import { TextFieldComponentPropsType } from './TextField.type';

export const TextFieldComponent = ({
  name,
  type,
  placeholder,
}: TextFieldComponentPropsType) => (
  <Field
    as={TextField}
    name={name}
    type={type}
    variant="outlined"
    placeholder={placeholder}
    fullWidth
  />
);
