import { SvgIcon } from '@mui/material';

export const AiIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <path
          opacity="0.35"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.33333 12.833L4.75 13.7497C4.83333 13.9163 4.91667 13.9997 5 14.083L5.91667 14.4997C6.33333 14.6663 6.33333 15.333 5.91667 15.4997L5 15.9163C4.83333 15.9997 4.75 16.083 4.75 16.2497L4.33333 17.1663C4.16667 17.583 3.5 17.583 3.33333 17.1663L2.91667 16.2497C2.83333 16.083 2.75 15.9997 2.66667 15.9163L1.75 15.4997C1.33333 15.333 1.33333 14.6663 1.75 14.4997L2.66667 14.083C2.83333 13.9997 2.91667 13.9163 2.91667 13.7497L3.33333 12.833C3.5 12.4163 4.16667 12.4163 4.33333 12.833Z"
          fill="white"
        />
        <path
          opacity="0.35"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.16667 2.08301L6.75 3.33301C6.83333 3.49967 7 3.66634 7.16667 3.74967L8.41667 4.33301C9 4.58301 9 5.41634 8.41667 5.66634L7.08333 6.24967C6.91667 6.33301 6.83333 6.41634 6.75 6.58301L6.16667 7.91634C5.91667 8.49967 5.08333 8.49967 4.83333 7.91634L4.25 6.58301C4.16667 6.41634 4.08333 6.33301 3.91667 6.24967L2.58333 5.66634C2 5.41634 2 4.58301 2.58333 4.33301L3.83333 3.74967C4 3.66634 4.16667 3.49967 4.25 3.33301L4.83333 2.08301C5.08333 1.49967 5.91667 1.49967 6.16667 2.08301Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1667 4.66699L15.6667 7.41699C15.8333 7.66699 16.0833 7.91699 16.3333 8.08366L19.0833 9.58366C19.6667 9.91699 19.6667 10.8337 19.0833 11.167L16.3333 12.667C16.0833 12.8337 15.8333 13.0837 15.6667 13.3337L14.1667 16.0837C13.8333 16.667 12.9167 16.667 12.5833 16.0837L11.0833 13.3337C10.9167 13.0837 10.6667 12.8337 10.4167 12.667L7.66667 11.167C7.08333 10.8337 7.08333 9.91699 7.66667 9.58366L10.4167 8.08366C10.6667 7.91699 10.9167 7.66699 11.0833 7.41699L12.5833 4.66699C12.9167 4.00033 13.8333 4.00033 14.1667 4.66699Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
