import { Box, Button } from '@mui/material';
import { btnSx, btnWrapperSx } from './AddSpecificationManuallySection.style';

export const AddSpecificationManuallySection = () => {
  return (
    <Box sx={btnWrapperSx}>
      <Button sx={btnSx}>I want to enter specification manually</Button>
    </Box>
  );
};
