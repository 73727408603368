export const formSx = {
  display: 'flex',
  width: '1146px',
  padding: '44px 44px 47px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '20px',
  background: '#FFF',
  boxShadow: '0px 0px 32px 0px rgba(21, 23, 46, 0.10)',
  flexDirection: 'column',
};

export const containerSx = {
  borderRadius: '12px',
  border: '1px solid #D7D8DB',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
};

export const formLabelSx = {
  textAlign: 'start',
  fontSize: '13px',
};

export const submitBtnSx = {
  marginTop: '10px',
  borderRadius: '12px',
  background:
    'linear-gradient(225deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%)',
  textTransform: 'none',
  color: '#FFF',
  padding: '14px 18px',
  fontSize: '16px',
  fontWeight: 600,
};

export const inputsBlock = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: 2,
  p: 2,
  width: '100%',
};
