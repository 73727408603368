import { SvgIcon } from '@mui/material';

export const FileIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          d="M16.9713 6.11167L12.638 1.77834C12.5127 1.65301 12.3433 1.58301 12.1667 1.58301H5.16666C4.42999 1.58301 3.83333 2.17967 3.83333 2.91634V17.583C3.83333 18.3197 4.42999 18.9163 5.16666 18.9163H15.8333C16.57 18.9163 17.1667 18.3197 17.1667 17.583V6.58301C17.1667 6.40634 17.0967 6.23701 16.9713 6.11167ZM12.5 6.91634C12.132 6.91634 11.8333 6.61767 11.8333 6.24967V2.85234L15.8973 6.91634H12.5Z"
          fill="#15172E"
        />
      </svg>
    </SvgIcon>
  );
};
