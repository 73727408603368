import { SvgIcon } from '@mui/material';

export const QuestionnaireIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16 2.66699H4.00001C3.26334 2.66699 2.66667 3.26366 2.66667 4.00033V16.0003C2.66667 16.737 3.26334 17.3337 4.00001 17.3337H16C16.7367 17.3337 17.3333 16.737 17.3333 16.0003V4.00033C17.3333 3.26366 16.7367 2.66699 16 2.66699ZM8.66667 6.66699H4.66667C4.29867 6.66699 4.00001 6.36833 4.00001 6.00033C4.00001 5.63233 4.29867 5.33366 4.66667 5.33366H8.66667C9.03467 5.33366 9.33334 5.63233 9.33334 6.00033C9.33334 6.36833 9.03467 6.66699 8.66667 6.66699ZM15.3333 7.33366H11.3333C10.9653 7.33366 10.6667 7.03499 10.6667 6.66699V5.33366C10.6667 4.96566 10.9653 4.66699 11.3333 4.66699H15.3333C15.7013 4.66699 16 4.96566 16 5.33366V6.66699C16 7.03499 15.7013 7.33366 15.3333 7.33366ZM8.66667 10.667H4.66667C4.29867 10.667 4.00001 10.3683 4.00001 10.0003C4.00001 9.63233 4.29867 9.33366 4.66667 9.33366H8.66667C9.03467 9.33366 9.33334 9.63233 9.33334 10.0003C9.33334 10.3683 9.03467 10.667 8.66667 10.667ZM15.3333 14.667H11.3333C10.9653 14.667 10.6667 14.3683 10.6667 14.0003C10.6667 13.6323 10.9653 13.3337 11.3333 13.3337H15.3333C15.7013 13.3337 16 13.6323 16 14.0003C16 14.3683 15.7013 14.667 15.3333 14.667ZM15.3333 11.3337H11.3333C10.9653 11.3337 10.6667 11.035 10.6667 10.667V9.33366C10.6667 8.96566 10.9653 8.66699 11.3333 8.66699H15.3333C15.7013 8.66699 16 8.96566 16 9.33366V10.667C16 11.035 15.7013 11.3337 15.3333 11.3337ZM8.66667 15.3337H7.33334C6.96534 15.3337 6.66667 15.035 6.66667 14.667V13.3337C6.66667 12.9657 6.96534 12.667 7.33334 12.667H8.66667C9.03467 12.667 9.33334 12.9657 9.33334 13.3337V14.667C9.33334 15.035 9.03467 15.3337 8.66667 15.3337Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
