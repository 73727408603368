import React, { ReactNode } from 'react';
import { inputWrapperSx } from './InputWrapper.style';
import { Box } from '@mui/material';

interface InputWrapperProps {
  children: ReactNode;
}

export const InputWrapper: React.FC<InputWrapperProps> = ({ children }) => {
  return <Box sx={inputWrapperSx}>{children}</Box>;
};
