// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore

export const commonBtnStyles = {
  borderRadius: '28px',
  width: '192px',
  padding: '12px',
  display: 'flex',
  color: '#FFF',
  border: 'none',
};

export const questionnaireBtnSx = {
  ...commonBtnStyles,
  background: 'rgba(255, 255, 255, 0.20)',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.20)',
    color: '#FFF',
    border: 'none',
  },
};

export const aiBtnSx = {
  ...commonBtnStyles,
  background:
    'linear-gradient(225deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%)',
};

export const btnsContainer = {
  justifyContent: 'space-between',
  width: '396px',
  padding: '28px 0 36px',
};
